.dodadmin-banner {
    background-color: #02192b;
    padding-top: 100px;
}

.dodadmin-banner .dodadmin-banner-content {
    box-shadow: 0px -12px 10px rgba(0, 0, 0, 0.43);
    width: 100%;
    padding: 20px 0;
    margin: 0 auto;
    min-height: 100%;
    max-width: 700px;
}

.dodadmin-detail {
    padding: 26px 0 86px;
}

.dodadmin-detail h1 {
    color: #113E60;
}

.dodadmin-detail p {
    max-width: 1146px;
    font-size: 20px;
}

.dodadmin-formtypes-input {
    background: #e8e8e8;
    color: #0e3c5f;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
    padding: 22px 105px 22px 24px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dodadmin-formtypes-box {
    background: #e8e8e8;
    color: #0e3c5f;
    display: flex;
    font-size: 20px;
    padding: 22px;
    width: 100%;
}

.dodadmin-formtypes-input:disabled{
    background: #ababab;
}

.dodadmin-submit-button {
    font-size: 20px !important;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    padding: 22px 35px;
    background: #69a242;
    box-shadow: inset 0px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 0;
    width: 100%;
    max-width: 370px;
    transition: all ease-in-out 0.3s;
    cursor: not-allowed !important;
  }
  
  .dodadmin-submit-button.active {
    background: #69a242;
    cursor: pointer !important;
  }

  .remove-dod-button.active {
    background: #d53f27;
    cursor: pointer !important;
  }

  .dodadmin-submit-button.active:hover {
    background: linear-gradient(180deg, #d53f27 0%, #6b3309 100%);
    cursor: pointer !important;
  }

@media (max-width: 1399px) {
    .dodadmin-submit-button {
      font-size: 25px;
      line-height: 36px;
    }
}

@media (max-width: 1199px) {
    .dodadmin-submit-button {
      font-size: 23px;
      line-height: 33px;
    }

}

@media (max-width: 991.98px) {
    .dodadmin-banner {
        padding-top: 130px;
        height: auto;
    }

    .dodadmin-banner-logo img {
        width: 200px;
    }
}

@media (max-width: 575px) {
    .dodadmin-detail {
        padding: 26px 0 56px;
    }

    .dodadmin-detail h1 {
        margin-bottom: 15px;
    }

    .dodadmin-detail-header {
        font-size: 30px !important;
        margin-top: 10px;
    }

    .dodadmin-detail p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .dodadmin-detail .talk-button {
        font-size: 18px !important;
        line-height: 25px;
        padding: 14px 20px;
        margin-bottom: 0;
        margin-top: 15px;
        width: 80%;
        min-width: 220px;
    }

    .dodadmin-banner {
        padding-top: 100px;
    }

    .dodadmin-banner-logo img {
        width: 150px;
    }

    .dodadmin-submit-button {
      font-size: 16px;
      line-height: 30px;
    }
}

@media (max-width: 390px) {
    .dodadmin-submit-button {
      font-size: 13px !important;
      line-height: 25px;
    }
}