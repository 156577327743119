.freetxpoolcontainer{
    max-width: 580px;
    width: 100%;
}

.freetxpoolcontainer-live{
    border: 1px solid #cb9f35;
    color: #cb9f35;
    border-radius: 5px;
    font-size: 14px !important;
    box-shadow: 0px 0px 3px #cb9f35;
    width: fit-content;
}

.freetxpoolcontainer-title{
    color: #cb9f35;
    font-size: 16px !important;
}