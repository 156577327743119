.wallet-banner {
  background-color: #02192b;
  min-height: 100vh !important;
  height: 100% !important;
}

.Toastify__toast-theme--light {
  background-color: #06071b !important;
}

.quest-gc-false{
  color: #FF0000
}

.quest-gc-true{
  color: #cb9f35;
}

.quest-congrats-text{
  font-size: 23px;
}

.button-border-green{
  border: 1px solid #a1c43a;
}

.wallet-banner {
  color: #154062;
}
.reward-dropdown {
  width: 100%;
  max-width: 580px;
}


.chain-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  margin: 0 !important;
  width: 100%;
}

.rewards-dropdown-options{
  width: 100%;
  max-width: 580px;
}

.reward-dropdown .dropdown-toggle {
  font-size: 28px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0 !important;
  width: 100%;
  padding: 22px 30px;
  margin-bottom: 19px;
}

.bg-button-green{
  background: linear-gradient(180deg, #69a242 0%, #a1c43a 100%) !important;
}

.reward-dropdown .dropdown-toggle::after {
  content: none;
}
.reward-dropdown .dropdown-toggle:active {
  border: 0;
}
.reward-dropdown .dropdown-menu {
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  width: 100%;
  max-height: 300px;
  transform: translate(0px, 87.5px);
  overflow-y: auto;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.reward-dropdown .dropdown-menu::-webkit-scrollbar { 
  display: none;  
}

.category-icons{
  width: 30px;
}

.accordion-container{
  max-width: 580px;
  margin: 0;
  font-family: "Poppins";
}

.accordion-title{
  font-size: 28px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0 !important;
  width: 100%;
  padding: 22px 30px;
  margin-bottom: 18px;
  text-align: center;
  cursor: pointer;
}

.category-reward-style{
  font-size: 20px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0 !important;
  width: 100%;
  padding: 22px 30px;
  margin-bottom: 0px;
}


.accordion-head-style{
  font-size: 28px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0 !important;
  width: 100%;
  padding: 22px 30px;
  margin-bottom: 0px;
}

.accordion-featured-head-style{
  font-size: 28px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #d53f27 0%, #f06125 100%) !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0 !important;
  width: 100%;
  padding: 22px 30px;
  margin-bottom: 0px;
}



.accordion-button-style{
  font-size: 28px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  border: 0 !important;
  width: 100%;
  padding: 0 !important;
  text-align: left;
}

.accordion-featured-button-style{
  font-size: 28px;
  line-height: 42px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #d53f27 0%, #f06125 100%) !important;
  border: 0 !important;
  width: 100%;
  padding: 0 !important;
  text-align: left;
}

.accordion-item-source{
  font-size: 14px;
  float: right;
  color: #bcbcbc;
}


.favorite-icon{
  width: 20px;
}

.favorite-icon:hover{
  color: #0360a3;
}

.favorite-icon:active{
  color: #f06125;
}

.reward-dropdown .dropdown-item {
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
  padding: 11px 29px;
}

.reward-dropdown .dropdown-item:hover {
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  color: #ffffff;
}

.reward-dropdown .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}
.arrow-bottom {
  margin-bottom: 15px;
}
.arrow-bottom img {
  transform: rotate(-90deg);
}
.transection-btn-wrapper {
  max-width: 580px;
}
.transection-btn {
  font-size: 20px !important;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  padding: 22px 35px;
  background: #d9d9d9;
  box-shadow: inset 0px 0px 13px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 0;
  width: 100%;
  max-width: 370px;
  transition: all ease-in-out 0.3s;
  cursor: not-allowed !important;
}
.transection-btn.active {
  background: linear-gradient(180deg, #7e4a34 0%, #6b3309 100%);
  cursor: pointer !important;
}
.transection-btn.active:hover {
  background: linear-gradient(180deg, #d53f27 0%, #6b3309 100%);
  cursor: pointer !important;
}
.selected-coin {
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  max-width: 580px;
  overflow: hidden;
  margin-top: 19px;
}
.selected-coin .coin-name {
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  padding: 22px;
}
.selected-coin .coin-reward-value {
  background: #e8e8e8;
  color: #0e3c5f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 105px 22px 24px;
  width: 100%;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.selected-coin .coin-reward-value:focus-visible {
  outline: 0;
}
.selected-coin .max {
  font-weight: 800;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  background: #0e3c5f;
  cursor: pointer;
  border-radius: 50px;
  padding: 3px 17px;
  text-transform: uppercase;
  position: absolute;
  font-family: "Poppins";
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
}

.exchange-card{
  width: fit-content;
}

.promo-code {
  font-size: 28px;
  color: #515151;
  position: absolute;
  max-width: 350px;
  width: 55%;
  overflow: hidden;
  bottom: 15%;
  left: 8%;
}

#promo-code-gc{
  font-size: 28px;
  color: #6b3309;
  position: absolute;
}

.no-code{
  font-size: calc(10px + 0.5vw);
  max-width: 350px;
  width: 55%;
  color: #0e3c5f;
  position: absolute;
  bottom: 13%;
  left: 8%;
} 
.copy-link {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #0e3c5f;
}
.copy-link-image{
  width: 30px;
}
.reward-btn {
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  padding: 30px 43px;
  background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0;
  width: 100%;
  max-width: 378px;
}


.home-btn {
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  padding: 30px 43px;
  background: linear-gradient(180deg, #1ea303 0%, #1ea303 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  border: 0;
  width: 100%;
  max-width: 378px;
}
.reward-wrapper {
  margin-top: 47px;
}
.back-btn {
  position: absolute;
  top: 31px;
  left: 38px;
  font-size: 25px;
  line-height: 123.5%;
  text-align: center;
  color: #3B71CA;
}

.shitcoin-chain-blockchain-choice-header{
  font-family: "Poppins";
  color: #3B71CA;
  font-weight: 700;
  font-size: 50px !important;
}

.selected-coin .coin-name {
  font-size: 20px !important;
}

.more-information-container{
  width: 100%;
  max-width: 580px;
  margin: auto;
}

.select-dropdown-menu,
.token-items,
.reward-dropdown-menu p{
  font-size: 20px !important;
}

.reward-token-toggle{
  white-space: nowrap; 
  width: 80%; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.bg-green{
  background: linear-gradient(180deg, #69a242 0%, #a1c43a 100%);
}

.tip-information{
  font-size: 14px;
  color: #0360a3;
}

.tip-information-bigger{
  font-size: 16px;
  color: #f06125;
}

@media (max-width: 1399px) {
  .reward-dropdown .dropdown-toggle,
  .selected-coin,
  .transection-btn,
  .reward-btn {
    font-size: 25px;
    line-height: 36px;
  }
  .home-btn {
    font-size: 25px;
    line-height: 36px;
  }
  .promo-code {
    font-size: 28px;
    line-height: 36px;
  }
}
@media (max-width: 1199px) {
  .reward-dropdown .dropdown-toggle,
  .selected-coin,
  .transection-btn,
  .reward-btn {
    font-size: 23px;
    line-height: 33px;
  }

  .accordion-title,
  .accordion-head-style,
  .accordion-button-style{
    font-size: 25px !important;
  }

  .home-btn {
    font-size: 23px;
    line-height: 33px;
  }
  .selected-coin .coin-reward-value {
    padding: 22px 90px 22px 17px;
  }
  .selected-coin .coin-name {
    padding: 29px 12px;
  }
  .selected-coin .max {
    font-size: 18px;
    line-height: 28px;
    padding: 3px 12px;
  }
  .promo-code {
    font-size: 25px;
    line-height: 34px;
  }
}
@media (max-width: 991.98px) {

  .wallet-banner {
    background: #02192b;
    min-height: 100vh;
    height: 100% !important;
    padding: 20px 0;
  }
  .back-btn img {
    width: 45px;
    height: 45px;
  }

  .rewards-dropdown-options,
  .accordion-container{
    margin: auto;
  }  

  .reward-dropdown {
    margin: 0 auto;
  }
  .reward-btn {
    padding: 22px 16px;
    max-width: 270px;
  }
  .home-btn {
    padding: 22px 16px;
    max-width: 270px;
  }
  .reward-wrapper {
    margin-top: 20px;
  }
  .selected-coin {
    margin: 0 auto 19px;
  }
  .transection-btn-wrapper {
    margin: 60px auto 0;
  }
  .promo-code {
    left: 10%;
  }
}
@media (max-width: 575px) {
  .quest-congrats-text{
    font-size: 16px;
  }


  .back-btn {
    font-size: 16px;
    top: 20px;
    left: 16px;
  }
  .back-btn img {
    width: 30px;
    height: 30px;
  }
  .arrow-bottom img {
    width: 40px;
    height: 40px;
  }
  .reward-dropdown .dropdown-toggle,
  .selected-coin,
  .transection-btn,
  .reward-btn {
    font-size: 16px;
    line-height: 30px;
  }

  .account-name{
    font-size: 12px;
  }

  .accordion-title,
  .accordion-head-style,
  .accordion-button-style{
    font-size: 16px !important;
  }

  .selected-coin .coin-name{
    font-size: 16px !important;
  }
  .home-btn {
    font-size: 20px;
    line-height: 30px;
  }
  .selected-coin .max {
    font-size: 12px;
    line-height: 24px;
    padding: 3px 6px;
  }
  .promo-code {
    font-size: 14px;
    line-height: 22px;
    left: 8%;
    bottom: 15%;
  }

  .no-code{
    font-size: 9.5px;
    bottom: 13%;
    left: 7%;
  }


  .shitcoin-chain-blockchain-choice-header{
    font-size: 30px !important;
  }

  .select-dropdown-menu,
  .token-items{
    font-size: 16px !important;
  }

  .reward-dropdown-menu{
    font-size: 14px !important;
  }

  .more-information{
    font-size: 14px !important;
  }
}
@media (max-width: 390px) {
  .quest-congrats-text{
    font-size: 14px;
  }


  .back-btn {
    font-size: 14px;
  }

  .back-btn img {
    width: 20px;
    height: 20px;
  }

  .arrow-bottom img {
    width: 30px;
    height: 30px;
  }
  
  .reward-dropdown .dropdown-toggle,
  .selected-coin,
  .transection-btn,
  .reward-btn {
    font-size: 16px;
    line-height: 30px;
  }


  .accordion-title,
  .accordion-head-style,
  .accordion-button-style{
    font-size: 16px !important;
  }

  .selected-coin .coin-name{
    font-size: 16px !important;
  }
  .home-btn {
    font-size: 20px;
    line-height: 30px;
  }
  .selected-coin .max {
    font-size: 12px;
    line-height: 24px;
    padding: 3px 6px;
  }

  .reward-dropdown .dropdown-toggle {
    font-size: 18px;
    padding: 20px;
  }
  .promo-code {
    bottom: 15%;   
  }

}

@media (max-width: 315px) {
  .account-name{
    font-size: 10px;
  }

  .promo-code {
    font-size: 8px;
  }

  .no-code{
    font-size: 7px;
  } 

  .reward-dropdown .dropdown-toggle,
  .selected-coin,
  .transection-btn,
  .reward-btn {
    font-size: 13px !important;
    line-height: 25px;
  }

  .select-dropdown-menu,
  .token-items{
    font-size: 13px !important;
  }

  .reward-dropdown-menu{
    font-size: 13px !important;
  }

  .more-information{
    font-size: 12px !important;
  }


  .accordion-title,
  .accordion-head-style,
  .category-reward-style,
  .accordion-item-title,
  .accordion-button-style{
    font-size: 13px !important;
  }

  .accordion-item-description,
  .accordion-item-source{
    font-size: 12px !important;
  }

  .selected-coin .coin-name{
    font-size: 13px !important;
  }

  .tip-information{
    font-size: 10px;
  }

  .category-icons{
    width: 20px;
  }

  .select-token-form{
    border: 1px solid green;
    padding: 0;
  }

}
