.tc-header{
    font-family: "Poppins";
    font-weight: 700;
    line-height: 100%;
    font-size: 50px !important;
    margin-top: 30px;
}

.tc-text-container{
    margin-top: 50px;
}

.tc-text-container h3{
    font-size: 25px !important;
    font-weight: 700;
}

.tc-text-container p{
    font-size: 20px !important;
}

@media (max-width: 1699px) {

}

@media (max-width: 1399px) {

}

@media (max-width: 1199px) {

}

@media (max-width: 575px) {
    .tc-header{
        font-size: 30px !important;
        margin-top: 20px;
    }
    
    .tc-text-container{
        margin-top: 20px;
    }

    .tc-text-container h3{
        font-size: 16px !important;
        font-weight: 700;
    }
    
    .tc-text-container p{
        font-size: 14px !important;
    }
    
}

