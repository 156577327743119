.metamaskicon{
    width: 30px;
    height: 30px;
}

.choose-heading {
    font-family: "Poppins";
    color: #3B71CA;
    font-weight: 700;
    text-align: center;
    font-size: 50px !important;
  }

  @media (max-width: 575px) {
    .choose-heading {
        font-size: 30px !important;
      }
  }