@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Black.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("./assets/fonts/MonaSans-Bold.otf");
  font-weight: 200;
  font-style: normal;
}

.test{
  border: 1px solid red !important;
}

.test2{
  border: 1px solid yellow !important;
}

.pointer-cursor{
  cursor: pointer;
}

.remove-focus{
  outline: 0!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body {
  font-family: "Poppins";
  font-weight: 400;
  background: #EDF1F7;
  overflow-x: hidden;
}

.landing-page{
  width: 100%;
  overflow-x: hidden;
}

.home-banner{
  display: block;
  position: relative;
}

.custom-container {
  padding: 0 100px;
  max-width: 1728px;
  margin: 0 auto;
}

.hideText {
  width: 500px;
  font-size: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
h1 {
  font-weight: 900;
  font-size: 64px;
  line-height: 123.5%;
  margin-bottom: 30px;
}
h2 {
  font-weight: 900;
  font-size: 58px;
  line-height: 70px;
  text-align: center;
  color: #154062;
}
h4 {
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: #154062;
  margin-bottom: 16px;
  text-align: justify;
}
p {
  font-size: 25px;
  line-height: 30px;
}
.text-green{
  color: #a1c43a;
}
.shit-coin-logo {
  max-width: 430px;
  margin: 0 auto;
}
.banner {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.banner .welcome-heading {
  font-weight: 600;
  font-size: 43px;
  line-height: 123.5%;
}
.banner-text-container{
  width: 100%;
  overflow-x: hidden;
}

.banner-text-link-container{
  width: 100%;
  overflow-x: hidden;
} 
.banner .banner-text {
  line-height: 123.5%;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 25px;
  max-width: 650px !important;
}

.banner .banner-text-link .banner-text-link-button{
  line-height: 123.5%;
  font-weight: 600 !important;
  font-size: 24px !important;
  border: 1px solid red;
  color: #a1c43a;
}

.banner .wallet-btn {
  font-size: 25px;
  font-weight: 400px;
  line-height: 50px;
  max-width: 330px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(180deg, #69a242 0%, #a1c43a 100%);
  border-radius: 35px;
  padding: 31px 65px;
  margin-top: 0 !important;
}

.banner .wallet-btn-link{
  color: #a1c43a;
  background: transparent;
  padding: 5px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}



.banner .add-to-product {
  background: #D83C27;
  font-size: 20px;
  font-weight: 400px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  padding: 31px 65px;
  margin-bottom: 38px;
}
.banner .read-more {
  font-size: 31px;
  line-height: 123%;
}
.banner .content-wrapper {
  padding-top: 100px;
}
.wallet-list {
  max-width: 423px;
  padding-left: 0;
}
.wallet-list li {
  background: linear-gradient(180deg, #154062 0%, #0360a3 100%);  
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  list-style: none;
  font-weight: 400;
  text-align: center !important;
  align-items: center !important;
  font-size: 25px;
  line-height: 42px;
  color: #ffffff;
  padding: 20px;
  cursor: pointer;
}

@media (max-width: 1599px) {
  h1 {
    font-size: 50px;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 46px;
    line-height: 60px;
  }
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
  p {
    font-size: 22px;
  }
  .banner .welcome-heading {
    font-size: 39px;
  }
  .banner .wallet-btn {
    font-size: 20px !important;
    line-height: 26px;
    padding: 25px 50px;
  }
  .banner .add-to-product {
    font-size: 24px;
    line-height: 26px;
    padding: 25px 50px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1399px) {
  .custom-container {
    padding: 0 80px;
  }
  h1 {
    font-size: 45px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 40px;
    line-height: 55px;
  }
  .shit-coin-logo {
    max-width: 350px;
  }
  .banner .welcome-heading {
    font-size: 32px;
  }
  .banner .add-to-product {
    padding: 18px 30px;
  }
}
@media (max-width: 1199px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .banner .welcome-heading {
    font-size: 27px;
  }
  .banner .wallet-btn {
    font-size: 20px !important;
    line-height: 26px;
    padding: 18px 30px;
  }
  .banner .add-to-product {
    font-size: 22px;
    line-height: 28px;
    padding: 18px 30px;
  }
}
@media (max-width: 991.98px) {

  h4 {
    text-align: center;
    font-size: 22px;
    line-height: 26px;
  }
  p {
    font-size: 20px;
  }
  .banner {
    text-align: center;
  }
  .banner .content-wrapper {
    padding-top: 50px;
  }

}
@media (max-width: 767px) {
  .custom-container {
    padding: 0 40px;
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .banner .welcome-heading {
    font-size: 22px;
  }
  .banner .content-wrapper {
    padding-top: 36px;
  }
  .banner .wallet-btn {
    font-size: 20px !important;
    line-height: 26px;
    padding: 16px 26px;
    margin-bottom: 20px;
  }
  .banner .add-to-product {
    font-size: 20px;
    line-height: 28px;
    padding: 16px 26px;
    margin-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .custom-container {
    padding: 0 12px;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 30px;
    line-height: 37px;
  }
  h4 {
    font-size: 18px;
    line-height: 22px;
  }
  p {
    font-size: 18px;
    line-height: 22px;
  }

  .banner .welcome-heading {
    font-size: 22px;
  }
  .banner .banner-text,
  .banner .read-more {
    font-size: 18px !important;
  }
  .banner .wallet-btn {
    width: 90%;
    margin:auto;
    font-size: 18px;
    line-height: 26px;
    padding: 16px 26px;
    margin-bottom: 20px;
  }

  .banner .add-to-product {
    width: 90%;
    margin:auto;
    font-size: 18px;
    text-align: center !important;
    min-width: 220px;
  }

  .wallet-list li {
    list-style: none;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 26px;
    color: #ffffff;
    padding: 15px;
    cursor: pointer;
  }

}
@media (max-width: 414px) {
  .banner .welcome-heading {
    font-size: 20px;
  }
  h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .banner .wallet-btn {
    font-size: 18px !important;
    line-height: 25px;
    padding: 14px 20px;
    margin-bottom: 15px;
    width: 80%;
    min-width: 220px;
  }
  .banner .add-to-product {
    font-size: 18px;
    line-height: 25px;
    padding: 14px 20px;
    margin-bottom: 15px;
    width: 80%;
    min-width: 220px;
  }
  .banner .banner-text {
    margin-bottom: 15px;
    font-size: 18px !important;
    line-height: normal;
  }
}

@media (max-width: 315px) {
  .banner .banner-text {
    margin-bottom: 15px;
    font-size: 13px !important;
    line-height: normal;
  }

  .banner .wallet-btn {
    font-size: 13px !important;
    line-height: 25px;
  }
}