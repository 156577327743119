.footer {
    background: #06071B;
    padding: 34px 0 15px;
    border: none;
}

.footer .row {
    padding: 40px 0 40px;
}

.footer-headers{
    color: #FFFFFF;
}

.footer .social-icons li {
    list-style: none;
}

.footer .social-icons li:not(:last-child) {
    margin-right: 15px;
}

.footer .social-icons li a {
    display: inline-block;
    transition: all ease-in-out 0.3s;
}

.social-media-icons{
    width: 25px;
}

.footer .social-icons li a:hover {
    transform: scale(1.1);
}

.footer .nav-links li a {
    font-size: 18px !important;
    color: #FFFFFF;
    text-decoration: none;
}

.footer .nav-links li:not(:last-child) {
    margin-bottom: 16px;
}

.footer .design-by {
    font-size: 10px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 6px;
    display: block;
}

.coin-market-logo {
    max-width: 200px !important;
    cursor: pointer;
}

.company-name{
    font-size: 16px;
}

@media (max-width: 1399px) {
    .footer .row {
        padding: 140px 0 120px;
    }

    .footer .nav-links li a {
        font-size: 26px;
        line-height: 38px;
    }
}

@media (max-width: 1199px) {
    .footer .row {
        padding: 120px 0 100px;
    }

    .footer .nav-links li a {
        font-size: 25px;
        line-height: 35px;
    }
}

@media (max-width: 991px) {
    .coin-market-logo {
        margin-bottom: 0;
        width: 100px !important;
    }

    .footer .row {
        padding: 80px 0;
    }

    .footer .nav-links li a {
        font-size: 23px;
        line-height: 33px;
    }
}

@media (max-width: 575px) {
    .footer .nav-links li a {
        font-size: 16px !important;
        line-height: normal;
    }

    .footer .social-icons li {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
    }


    .footer-logo-section{
        display: flex;
        padding: 0;
        margin-top: 20px;
    }

    .coin-market-logo {
        margin-bottom: 0;
        width: 60px !important;
    }

    .footer-logo-section-text{
        height: 100%;
        position: relative;
    }

    .social-media-icons{
        margin: 0;
    }

    .social-icons-container,
    .social-icons-container a{
        padding: 0;
        margin: 0;
    }

    .company-name{
        font-size: 16px;
    }
}

@media (max-width: 315px) {
    .footer .nav-links li a {
        font-size: 13px !important;
        line-height: normal;
    }

    .footer .social-icons li {
        width: 20px;
        height: 20px;
        margin-bottom: 10px;
    }


    .coin-market-logo {
        margin-bottom: 0;
        width: 40px !important;
    }


    .company-name{
        font-size: 13px;
    }
}