.quest-header {
    font-family: "Mona-Sans";
    font-weight: 700;
    line-height: 120%;
    font-size: 55px !important;
    background: linear-gradient(60deg, #FFFFFF 0%,
            #FFFFFF 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.quest-info-container {
    max-width: 830px;
}

.quest-image-container {
    position: relative;
}

.quest-img {
    width: 200px !important;
}

.quest-img-shitty{
    width: 400px !important;
}

.plena-bg {
    color: #85EAFF;
    background: linear-gradient(110deg, #85EAFF 0%, #DADADA 50%, #FFCB80 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.solana-bg {
    color: #9945FF;
    background: linear-gradient(120deg, #9945FF 0%, #14F195 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.shitcoinx-bg {
    color: #883621;
    background: linear-gradient(120deg, #883621 0%, #FDC57E 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.quest-text-col p {
    font-size: 20px;
    color: #818183;

}

.plena-text a {
    cursor: pointer;
    color: #85EAFF;
    background: linear-gradient(110deg, #85EAFF 0%, #DADADA 50%, #FFCB80 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.solana-text a{
    cursor: pointer;
    color: #9945FF;
    background: linear-gradient(120deg, #9945FF 0%, #14F195 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.shitcoinx-text a{
    cursor: pointer;
    color: #883621;
    background: linear-gradient(120deg, #883621 0%, #FDC57E 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}



@media (max-width: 1699px) {}

@media (max-width: 1399px) {}

@media (max-width: 1199px) {}

@media (max-width: 991px) {}

@media (max-width: 575px) {

    .quest-icon {
        display: none;
    }

    .quest-header {
        font-family: "Poppins";
        font-weight: 700;
        font-size: 40px !important;
        margin-top: 5px !important;
    }

    .quest-img {
        width: 180px !important;
    }

    .quest-img-shitty {
        width: 360px !important;
    }

    .quest-text-col p {
        font-size: 16px;
        font-weight: 600;
    }

}

@media (max-width: 315px) {
    .quest-header {
        font-weight: 700;
        font-size: 28px !important;
        margin-top: 5px !important;
    }

    .quest-img {
        width: 100px !important;
    }

    .quest-img-shitty {
        width: 200px !important;
    }

    .quest-text-col p {
        font-size: 12px;
        font-weight: 600;
    }


}