.accordion-container{
    max-width: 580px;
    font-family: "Aeonik";
}

.accordion-title{
    font-size: 32px;
    line-height: 42px;
    color: #ffffff !important;
    background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
    border-radius: 10px;
    border: 0 !important;
    width: 100%;
    padding: 22px 30px;
    margin-bottom: 18px;
    text-align: center;
    cursor: pointer;
}


.accordion-head-style{
    font-size: 28px;
    line-height: 42px;
    color: #ffffff !important;
    background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.95);
    border-radius: 10px;
    border: 0 !important;
    width: 100%;
    padding: 22px 30px;
    margin-bottom: 0px;
  }


.accordion-button-style{
    font-size: 28px;
    line-height: 42px;
    color: #ffffff !important;
    background: linear-gradient(180deg, #154061 0%, #0360a3 100%);
    border: 0 !important;
    width: 100%;
    padding: 22px 30px;
  }