.partner-banner{
    background-image: url('../../assets/images/partner-banner-bg.png');
    background-size: cover;
    padding-top: 170px;
    height: 460px;
}
.partner-banner .partner-banner-content{
    background: #D53D27;
    box-shadow: 0px -12px 10px rgba(0, 0, 0, 0.43);
    width: 100%;
    padding: 20px 0;
    height: 100%;
}
.partner-detail{
    padding: 26px 0 86px;
}

.partner-detail h1{
    color: #113E60;
}

.partner-detail p{
    max-width: 1146px;
    font-size: 20px;
}

.partner-detail-header{
    font-family: "Poppins";
    font-weight: 700;
    line-height: 100%;
    font-size: 50px !important;
    margin-top: 30px;
}

.partner-detail .talk-button{
    font-weight: 400;
    font-size: 20px !important;
    text-align: center;
    color: #FFFFFF;
    padding: 18px 84px 17px;
    background: linear-gradient(180deg, #D53D27 0%, #F06024 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.63);
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
}
.partner-detail .talk-button:hover{
    transform: scale(1.1);
}
@media (max-width: 1599px) {

}
@media (max-width: 991px){
    .partner-banner{
        padding-top: 130px;
        height: auto;
    }
    .partner-banner-logo img{
        width: 200px;
    }
}
@media (max-width: 575px){
    .partner-detail {
        padding: 26px 0 56px;
    }
    .partner-detail h1{
        margin-bottom: 15px;
    }
    .partner-detail-header{
        font-size: 30px !important;
        margin-top: 10px;
    }
    .partner-detail p{
        font-size: 14px;
        margin-bottom: 15px;
    }
    .partner-detail .talk-button {
        font-size: 18px !important;
        line-height: 25px;
        padding: 14px 20px;
        margin-bottom: 0;
        margin-top: 15px;
        width: 80%;
        min-width: 220px;
    }
    .partner-banner {
        padding-top: 100px;
    }
    .partner-banner-logo img {
        width: 150px;
    }
}