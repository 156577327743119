.policy-detail {
  padding: 26px 0 86px;
}

.policy-detail h1 {
  font-family: "Aeonik";
  font-weight: 700;
  line-height: 100%;
  font-size: 50px !important;
  margin-top: 30px;
  color: #113e60;
}

.policy-detail h3 {
  font-size: 20px !important;
}

.policy-detail p {
  max-width: 1146px;
  margin: 0;
}

.policy-text-container {
  margin-top: 50px;
}

.policy-text-container h3{
  font-size: 25px !important;
  font-weight: 700;
  color: #000000;
}

.policy-text-container h4{
  font-size: 23px !important;
  font-weight: 700;
  color: #000000;
  margin-top: 15px;
}

.policy-detail h5 {
  font-size: 16px !important;
}

.policy-text-container p,
.policy-text-container li {
  font-size: 20px !important;
  margin-bottom: 10px;
}

@media (max-width: 1599px) {

  .policy-detail .talk-button {
    font-size: 34px;
    line-height: 50px;
    padding: 15px 50px 14px;
  }
}

@media (max-width: 991px) {
  .policy-detail h1 {
    margin-bottom: 25px;
  }

  .policy-detail .talk-button {
    font-size: 30px;
    line-height: 45px;
  }
}

@media (max-width: 575px) {
  .policy-detail {
    padding: 0;
  }

  .policy-detail h1 {
    margin-bottom: 20px;
    font-size: 30px !important;
  }

  .policy-detail p,
  .policy-text-container li  {
    margin: 0 !important;
    font-size: 14px !important;
  }

  .policy-text-container {
    margin-top: 20px;
  }

  .policy-text-container h3,
  .policy-text-container h4 {
    font-size: 16px !important;
  }

  .policy-detail h5 {
    font-size: 12px !important;
  }
}