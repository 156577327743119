.home-banner {
  background: linear-gradient(180deg, #191B1F 0%, #06071B 100%);
  color: #fff;
  overflow: hidden;
}


.welcome-heading {
  font-family: "Mona-Sans";
  font-size: 65px !important;
  letter-spacing: 1px;
  background: linear-gradient(60deg, #FFFFFF 0%,
      #818183 50%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

}

.welcome-text {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 60px !important;
}

#check-docs-button {
  font-size: 20px;
  font-weight: 400px;
  line-height: 50px;
  padding: 0 !important;
  width: auto;
  color: #a1c43a;
  background: transparent;
  border: none;
}

.banner-icons{
  width: 30px;
}

.product-hunt-img{
  width: 250px;
  height: 54px;
}

@media (max-width: 575px) {
  .home-banner {
    height: 100%;
    min-height: 500px;
    position: relative;

  }

  .welcome-heading {
    font-family: "Mona-Sans";
    font-size: 50px !important;
  }


  .welcome-text {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 35px !important;
    margin-top: 20px !important;
  }

  .cancel-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  #check-docs-button {
    font-size: 16px;
  }

  .banner-icons{
    width: 20px;
  }
}

@media (max-width: 315px) {
  .welcome-heading {
    font-family: "Mona-Sans";
    font-size: 30px !important;
  }

  #check-docs-button {
    font-size: 13px;
  }

  .product-hunt-img{
    width: 150px;
  }

}